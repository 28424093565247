.icon {
  display: inline-block;

  &--right {
    align-self: flex-end;
    margin-left: $grid-gutter-width-base / 2;
  }

  &__24x24_marina {
    @extend %icon__24x24_marina;
  }

  &__16x16_check {
    @extend %icon__16x16_check;
    
    &.terrace-area {
      background: none;
      width: auto;
      height: auto;
    }
  }

  &__16x16_check-inactive {
    @extend %icon__16x16_check-inactive;
  }

  &__24x24_facebook {
    @extend %icon__24x24_facebook-grey;
  }

  &__24x24_fb {
    @extend %icon__24x24_facebook-share;
  }

  &__24x24_vk {
    @extend %icon__24x24_vk;
  }

  &__24x24_linkedin {
    @extend %icon__24x24_linkedin;
  }

  &__24x24_arrow {
    @extend %icon__24x24_arrow;
  }

  &__24x24_arrow-blue {
    @extend %icon__24x24_arrow-blue;
  }

  &__24x24_arrow-back {
    @extend %icon__24x24_arrow-blue;

    transform: rotate(90deg);
  }

  &__24x24_arrow-left {
    @extend %icon__24x24_arrow-left;
  }

  &__24x24_arrow-right {
    @extend %icon__24x24_arrow-left;

    transform: rotate(180deg);
  }

  &__24x24_listing {
    @extend %icon__24x24_listing;

    .list-group-item:hover > &,
    .active > & {
      @extend %icon__24x24_listing-white;
    }
  }

  &__24x24_parking {
    @extend %icon__24x24_parking;
  }

  &__24x24_gallery {
    .list-group-item:hover > &,
    .active > & {
      @extend %icon__24x24_gallery-white
    }

    @extend %icon__24x24_gallery;
  }

  &__24x24_gallery-white {
    @extend %icon__24x24_gallery-white;
  }

  &__24x24_download-white {
    @extend %icon__24x24_download-white;
  }

  &__24x24_virtual-tour-white {
    @extend %icon__24x24_virtual-tour-white;
  }

  &__hamburger-menu {
    &.hamburger {
      display: inline-flex;
      align-items: center;
      height: 54px;
    }

    &:hover {
      @include mb-down(sm) {
        opacity: 1 !important;
      }
    }

    .aside-menu-opened & {
      @extend .is-active;
    }
  }

  &__128x128_rounded-circle {
    align-items: center;
    background-color: $gray-light;
    display: flex;
    width: 128px;
    height: 128px;
  }

  &__48x48_map-all-pins {
    @extend %icon__48x48_map-all-pins;
  }

  &__48x48_education {
    @extend %icon__48x48_education;
  }

  &__48x48_shopping {
    @extend %icon__48x48_shopping;
  }

  &__48x48_entertainment {
    @extend %icon__48x48_entertainment;
  }

  &__48x48_sports {
    @extend %icon__48x48_sports;
  }

  &__48x48_food {
    @extend %icon__48x48_food;
  }

  &__48x48_transport {
    @extend %icon__48x48_transport;
  } 
}
