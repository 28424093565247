.mapify-hover {
  fill: rgba(0, 85, 166, 0.5);
}

.section__area-plans {
  .mapify-holder {
    width: 100%;
  }

  .mapify-imgHolder {
    .mapify {
      width: 100%;
    }
  }
}
