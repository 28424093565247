.page-template-template-location,
.page-template-template-environment {
  .section {
    &__map {
      height: calc(100vh - 90px);

      @include mb-up(sm) {
        height: 600px;
      }

      @include mb-up(lg) {
        height: 750px;
      }
    }
  }
}

.section {
  &__map-navigation {
    border-bottom: 1px solid $gray-light;
    border-top: 1px solid $gray-light;
  }
}

.map-navigation {
  display: flex;
  margin: 0;
  padding: 0;

  @include mb-only(md) {
    width: 100%;
  }

  &__title {
    display: block;
    margin-top: 10px;
  }

  &__icon {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  li {
    border-left: 1px solid $gray-light;
    border-bottom: 0px solid $gray-light;
    cursor: pointer;
    display: inline-flex;
    padding: 25px 20px;
    position: relative;
    flex-direction: column;
    transition: 0.2s linear background;
    overflow: hidden;

    @include mb-only(md) {
      @include make-col-ready();
    }

    &:after {
      background-color: $brand-default;
      content: '';
      position: absolute;
      left: 0;
      bottom: -4px;
      right: 0;
      height: 4px;
      transition: 0.2s all ease;
    }

    &:last-child {
      border-right: 1px solid $gray-light;
    }

    &:hover,
    &.active {
      background-color: $gray-light;
    }

    &.active {
      &:after {
        bottom: 0;
      }
    }
  }
}
