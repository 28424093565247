.section {
  &__news {

  }
}

.news-post {
  margin-bottom: 20px;

  &__link {
    display: block;
    padding-bottom: 30px;
    position: relative;

    &:hover,
    &:active,
    &:focus {
      text-decoration: none;

      .news-post__title {
        border-color: $brand-default;
        color: $brand-default;
      }
    }
  }

  &__figure {
    margin-bottom: 0;
    position: relative;

    &:after {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      right: 0;
      z-index: 1;
      background-image: linear-gradient(to bottom, rgba(77, 77, 79, 0), rgba(77, 77, 79, 0.8) 69%, #4d4d4f);
    }
  }

  &__image {
    @include mb-down(md) {
      width: 100%;
    }
  }

  &__title {
    border-left: 10px solid #e5e5e5;
    padding-left: 30px;
    margin-top: 30px !important;
    margin-bottom: 0;
    transition: 0.2s all ease;

    @include mb-down(md) {
      border-left-width: 5px;
      padding-left: 25px;
      font-size: $font-size-h4;
    }
  }

  &__datetime {
    color: #fff;
    position: absolute;
    left: 30px;
    padding-left: 30px;
    bottom: 30px;
    z-index: 2;

    &:before {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      top: 50%;
      margin-top: -8px;

      @extend %icon__16x16_calendar;
    }
  }
}

.single-post {
  .type-post {
    header {
      .entry-title {
        @include mb-up(lg) {
          margin-bottom: 90px;
          margin-top: 50px;
        }

        @include mb-down(sm) {
          font-size: $font-size-h1;
          margin-top: 2rem;
        }
      }
    }

    .entry-content {
      background-color: $white;
      padding: 90px 60px;
      position: relative;

      @include mb-up(md) {
        margin-left: 64px;
      }

      @include mb-down(sm) {
        padding: 4rem 2rem;
      }

      p:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.post {
  &__sharing {
    @include media-breakpoint-up(md) {
      position: absolute;
      top: 0;
      margin-left: -64px;
      left: 0;
    }

    // Ugly ass dirty force
    @include media-breakpoint-down(sm) {
      bottom: auto !important;
      width: auto !important;
      position: relative !important;
      top: auto !important;
      margin-top: 30px !important;
      left: auto !important;
      margin-left: 0 !important;
    }

    ul {
      list-style: none;
      margin: 0;
      padding: 0;
    }

    li {
      margin-bottom: 2px;

      &:last-child {
        margin-bottom: 0;
      }

      @include media-breakpoint-down(sm) {
        display: inline-flex;
      }
    }

    a {
      align-items: center;
      background-color: $gray-light;
      display: flex;
      justify-content: center;
      width: 64px;
      height: 64px;
      transition: 0.2s background ease;

      &:hover {
        background-color: $white;
      }

      &.bg-default {
        &:hover {
          background-color: darken($brand-default, 5%);
        }
      }
    }
  }
}
