.section {
  &__floorplan {
    @include mb-up(md) {
      height: 100%;
    }

    &.loading--fixed {
      @include mb-down(sm) {
        &:before {
          position: absolute;
        }
      }
    }
  }
}

.floorplan {
  &__navigation,
  &__content {
    @include make-col-ready();

    max-height: 100%;
  }

  &__navigation {
    padding: 50px;
    width: 400px;

    @include mb-down(sm) {
      padding: $grid-gutter-width-base;
      width: 100%;
    }

    @include mb-up(md) {
      right: calc(100% - 400px);
    }
  }

  &__content {
    overflow-y: scroll;
    width: calc(100% - 400px);

    @include mb-down(sm) {
      padding-left: 0;
      padding-right: 0;
      overflow-y: auto;
      width: 100%;
    }

    @include mb-up(md) {
      left: 400px;
    }

    .close {
      position: absolute;
      top: 50px;
      right: 50px;
    }

    .apartment-plan__compass {
      right: auto;
      left: 50px;
      top: 50px;
    }
  }

  &__legend {

  }

  &__image {
    display: block;
  }

  &__indicator {
    display: inline-block;
    width: 18px;
    height: 18px;

    &--free {
      background-color: #fff;
    }

    &--booked {
      background-color: #fde2be;
    }

    &--sold {
      background-color: #ffdfdf;
    }
  }

  &__tooltip {
    position: absolute;
    z-index: 2;
    text-align: left;

    &-count {
      background-color: $brand-default;
      color: #fff;
      display: block;
      float: left;
      font-weight: bold;
      line-height: 32px;
      text-align: center;
      width: 32px;
      height: 32px;
    }

    &-content {
      background-color: $gray-light;
      display: block;
      float: left;
      line-height: 1.4;
      padding: 10px 13px;
    }
  }

  &__image-wrap {
    position: relative;
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
  }

  &__image-map {
    &--free {
      &.mapify-polygon {
        fill: transparent;

        &.mapify-hover {
          fill: rgba(0, 85, 166, 0.1);
        }
      }
    }

    &--booked {
      &.mapify-polygon {
        fill: rgba(253, 226, 190, 0.5);

        &.mapify-hover {
          fill: rgba(253, 226, 190, 0.2);
        }
      }
    }

    &--sold {
      &.mapify-polygon {
        fill: rgba(255, 222, 222, 0.5);

        &.mapify-hover {
          fill: rgba(255, 222, 222, 0.2);
        }
      }
    }
  }

  &__switcher {

  }
}

.floorplan-switcher {
  &__title {
    color: #fff;
    font-size: $font-size-h1;
    font-weight: 300;
    line-height: 86px;
    text-transform: uppercase;
  }

  &__inner-wrap {
    position: relative;
  }

  &__counter {
    background-color: #fff;
    border: 10px solid rgb(229, 229, 229);
    line-height: 66px;
    margin-right: 42px;
    text-align: center;
    width: 86px;
    height: 86px;

    span {
      color: $text-color;
      font-size: 55px;
      font-weight: 300;
    }
  }

  &__button {
    background-color: $text-color;
    cursor: pointer;
    display: block;
    position: absolute;
    right: 0;
    width: 32px;
    height: 32px;

    &:after {
      content: '';
      display: block;
      position: absolute;
      margin-top: 50%;
      margin-left: 50%;
      left: -8px;
      top: -8px;
      width: 16px;
      height: 16px;

      @extend %icon__16x16_arrow-down;
    }

    &--add {
      top: 0;
      transform: rotate(180deg);
    }

    &--subtract {
      bottom: 0;
    }
  }
}
