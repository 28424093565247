.aside-menu {
  bottom: 0;
  position: fixed;
  left: 100%;
  top: 0;

  background-color: $aside-bg-color;
  overflow-y: scroll;
  padding: $aside-padding;
  z-index: $aside-z-index;
  width: 100%;

  @extend %oi-offcanvas-transition;

  .admin-bar & {
    top: 32px;
  }

  &__nav {
    max-width: $aside-width - 50px - 50px; // 50px = padding
  }

  &:before {
    content: '';
    opacity: 0;
    right: 0;
    transition: $aside-bg-transition;
    visibility: hidden;
    z-index: $aside-z-index - 1;

    @extend %oi-backdrop !optional;
  }
}

ul.aside-navigation {
  li {
    font-size: $lead-font-size;
    line-height: 1.25;
    margin-bottom: 10px;

    a {
      color: #fff;
    }
  }

  > li.menu-item {
    margin-bottom: 30px;

    > a {
      font-weight: 500;
      text-transform: uppercase;
    }
  }

  ul {
    @extend .list-unstyled;

    margin: 15px 0 0 10px;
  }
}
