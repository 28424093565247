@import '../client-visit/fonts';

$dark: #222232;
$dark-blueish: #001736;
$dark-lighter: #444460;
$peach: #db9778;
$border-radius: 5px;
$font-size-xs: 11px;
$font-size-xxs: 10px;
$spacer: 8px;
$letter-spacing: 4px;

$transition-cubic: cubic-bezier(0.2, 0.6, 0, 1);
$transition-base: all 0.4s $transition-cubic;

html {
  scroll-behavior: smooth;
}

.hamburger {
  background-color: transparent !important;
}

.teaser-banner {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
  transition: $transition-base;

  @include media-breakpoint-only(xs) {
    left: $spacer * 2;
    right: $spacer * 2;
    overflow: scroll;

    .aside-menu-opened & {
      height: 100%;
    }
  }

  .container {
    @include media-breakpoint-down(lg) {
      width: 100%;
      padding-left: 0;
      padding-right: 0;
    }
  }

  &--hidden {
    body:not(.aside-menu-opened) & {
      transform: translateY(-100%);
    }
  }

  &--scrolling {
    @include media-breakpoint-down(lg) {
      .teaser-banner__wrap {
        background-color: $dark;
      }
    }
  }

  &__contacts {
    display: none;

    .aside-menu-opened & {
      background-color: #F9F7F5;
      flex: 0 0 calc(100% + #{$spacer * 8});
      margin-top: auto;
      margin-left: - $spacer * 4;
      margin-right: - $spacer * 4;
      margin-bottom: - $spacer * 3;

      @include media-breakpoint-down(lg) {
        display: flex;
        z-index: 10;
        padding: 40px 85px 60px;
        align-items: center;
      }

      @include media-breakpoint-only(xs) {
        justify-content: center;
        flex-direction: column;
        text-align: center;
        padding: 30px 16px 30px;
      }

      @media (max-height: 600px) {
        padding: 30px 16px 20px;
      }
    }

    .btn {
      @include media-breakpoint-only(xs) {
        font-size: 9px;
        padding: 14px 16px;
        width: auto;
        letter-spacing: 3px;
      }
    }

    &-title {
      font-size: 26px;
      line-height: 1.15;
      margin-bottom: 20px;

      @extend %heading-font;

      @include media-breakpoint-only(xs) {
        display: none;
      }
    }

    &-booking {
      max-width: 290px;
    }

    &-information {
      flex-grow: 1;
      text-align: right;
      font-size: 16px;

      @include media-breakpoint-only(xs) {
        display: flex;
        align-items: center;
        margin-top: 20px;
      }

      @media (max-height: 600px) {
        font-size: 13px;
      }

      a {
        color: $body-color;
      }

      .contact-information {
        &__title {
          @include media-breakpoint-up(sm) {
            margin-bottom: 45px;
          }
        }

        &__title,
        &__phone {
          @include media-breakpoint-only(xs) {
            margin-right: $spacer;
          }
        }
      }
    }
  }

  &__menu-trigger {
    cursor: pointer;
    position: absolute;
    z-index: 2;
    margin-right: -15px;
    top: 45px;
    right: $spacer * 3;

    @include media-breakpoint-only(xs) {
      top: 7px;
      right: $spacer * 2;
    }

    .contacts__menu-text {
      transition: $transition-base;
    }

    .aside-menu-opened & {
      .contacts__menu-text {
        opacity: 0;
        pointer-events: none;
      }
    }

    .hamburger {
      height: auto;
    }
  }

  &__brand {
    margin-bottom: $spacer;
    z-index: 3;

    @include media-breakpoint-down(lg) {
      margin-right: auto;
    }

    @include media-breakpoint-down(sm) {
      margin-bottom: 0;
    }

    @include media-breakpoint-only(xs) {

      img {
        max-height: 40px;
        width: auto;
      }
    }
  }

  &__wrap {
    background-color: $dark;
    display: flex;
    padding: $spacer * 3 $spacer * 4 $spacer * 2;
    align-items: flex-end;
    justify-content: space-evenly;
    transition: $transition-base;
    position: relative;

    @include media-breakpoint-only(xl) {
      overflow: hidden;
    }

    @include media-breakpoint-down(lg) {
      max-width: calc(100vw - 80px);
      margin-left: auto;
      margin-right: auto;

      body:not(.page-template-template-location):not(.aside-menu-opened) .teaser-banner:not(.teaser-banner--scrolling) & {
        background-color: transparent;
      }

      body.aside-menu-opened & {
        background-color: $dark;
        overflow: hidden;
        align-items: flex-start;
        justify-content: center;
        flex-wrap: wrap;
      }
    }

    @include media-breakpoint-down(sm) {
      max-width: 700px;
      padding: $spacer * 3 $spacer * 4;
    }

    @include media-breakpoint-only(xs) {
      align-items: flex-start;
      padding: $spacer * 2;
    }
  }

  &__language {
    display: flex;
    align-items: center;
    position: absolute;
    z-index: 3;
    transition: $transition-base;

    @extend %text-font;

    @include media-breakpoint-only(xl) {
      background-color: $dark;
      left: 100%;
    }

    &--opened {
      @include media-breakpoint-only(xl) {
        left: auto;
        right: $spacer * 3;
        padding-left: $spacer * 6;
      }
    }

    @include media-breakpoint-down(lg) {
      display: none;

      .aside-menu-opened & {
        display: flex;
        position: absolute;
        right: $spacer * 12;
        top: $spacer * 5 + $spacer / 2;
      }
    }

    @include media-breakpoint-only(xs) {
      .aside-menu-opened & {
        flex-direction: column;
        top: $spacer * 2;
        right: $spacer * 7;
      }
    }
  }

  &__languages {
    color: $white;
    text-transform: uppercase;
    position: relative;
    z-index: 10;
    display: flex;
    align-items: center;
    margin: 0;
    list-style: none;
    padding: 0;

    @include media-breakpoint-down(lg) {
      display: none;

      .aside-menu-opened & {
        display: flex;
      }
    }

    @include media-breakpoint-only(xs) {
      .aside-menu-opened & {
        flex-direction: column;
      }
    }

    li {
      @include media-breakpoint-up(sm) {
        margin-left: $spacer * 2;
      }

      a {
        color: #909090;
        display: flex;
        align-items: center;
        transition: $transition-base;
        font-size: $font-size-xxs;
        letter-spacing: $letter-spacing;
        font-weight: 600;
        padding-bottom: $spacer;
        padding-top: $spacer;

        @extend %text-font;

        @include media-breakpoint-down(lg) {
          font-size: 13px;
        }

        @include media-breakpoint-only(xs) {
          font-size: $font-size-xxs;
          padding-bottom: 2px;
          padding-top: 2px;
        }

        @include hover-focus-active {
          color: $white;
          text-decoration: none;
        }
      }
    }
  }

  &__language-active {
    color: $white;
    display: flex;
    align-items: center;
    transition: $transition-base;
    font-size: $font-size-xxs;
    letter-spacing: $letter-spacing;
    font-weight: 600;
    padding-bottom: $spacer;
    padding-top: $spacer;
    cursor: pointer;

    @include media-breakpoint-only(xl) {
      transform: translateX(calc(-100% - #{$spacer * 3}));

      &::before {
        background: transparent url("../images/teaser-language-arrow-white.svg") no-repeat center center;
        content: "";
        width: 20px;
        height: 17px;
        margin-right: $spacer;
        display: block;
        transition: $transition-base;
      }
    }

    @include media-breakpoint-down(lg) {
      font-size: 13px;
    }

    @include media-breakpoint-only(xs) {
      font-size: $font-size-xxs;
      padding-top: 2px;
      padding-bottom: 2px;
    }

    .teaser-banner__language--opened & {
      transform: none;

      &::before {
        transform: rotate(180deg);
      }
    }

    @extend %text-font;

    @include hover-focus-active {
      color: $peach;

      &::before {
        background: transparent url("../images/teaser-language-arrow-white.svg") no-repeat center center;
        transform: translateX(- #{$spacer});
      }

      .teaser-banner__language--opened & {
        &::before {
          transform: translateX(- #{$spacer}) rotate(180deg);
        }
      }
    }
  }

  &__menu {
    display: flex;
    align-items: center;
    list-style-type: none;
    margin: 0;
    padding: 0;
    flex-grow: 1;
    justify-content: center;
    transition: $transition-base;
    opacity: 1;

    @include media-breakpoint-only(xl) {
      padding-right: $spacer * 4;
    }

    @include media-breakpoint-down(lg) {
      display: none;
      background-color: $dark;
      z-index: 1;
      justify-content: center;
      animation-duration: 0.4s;
      animation-fill-mode: both;
      animation-name: dropdownSlideIn;
      flex: 0 0 100%;

      .aside-menu-opened & {
        display: flex;
        flex-direction: column;
      }
    }

    @include media-breakpoint-only(xs) {
      padding-bottom: $spacer * 8;
      padding-top: $spacer * 10;
    }

    @media (max-height: 600px) {
      padding-top: $spacer * 7;
      padding-bottom: $spacer * 6;
    }

    li {
      margin-left: $spacer;
      margin-right: $spacer;

      @include media-breakpoint-down(lg) {
        animation-duration: 0.4s;
        animation-fill-mode: both;
        animation-name: dropdownSlideIn;

        @for $i from 1 through 10 {
          &:nth-child(#{$i}) {
            animation-delay: $i * 0.07s;
          }
        }

        & + li {
          margin-top: $spacer * 4;

          @include media-breakpoint-only(lg) {
            margin-top: $spacer * 3;
          }

          @include media-breakpoint-only(xs) {
            margin-top: $spacer;
          }

          @media (max-height: 600px) {
            margin-top: 0;
          }
        }
      }

      a {
        color: $white;
        display: block;
        padding: $spacer;
        text-transform: uppercase;
        font-size: $font-size-xxs;
        letter-spacing: $letter-spacing;
        font-weight: 600;
        transition: $transition-base;
        text-align: center;

        @extend %text-font;

        @include hover-focus-active {
          color: $peach;
          text-decoration: none;
        }

        @include media-breakpoint-down(lg) {
          font-size: 13px;
        }

        @media (max-height: 600px) {
          font-size: 10px;
        }
      }

      &.active,
      &.banner__send-enquiry {
        a {
          color: $peach;
        }

        @include hover-focus-active {
          a {
            color: $white;
          }
        }
      }
    }
  }
}
