

@font-face {
  font-family: 'Proba Pro';
  src: url('../fonts/Proba Pro Bold Italic.eot');
  src: url('../fonts/Proba Pro Bold Italic.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Proba Pro Bold Italic.woff2') format('woff2'),
  url('../fonts/Proba Pro Bold Italic.woff') format('woff'),
  url('../fonts/Proba Pro Bold Italic.svg#Proba Pro Bold Italic') format('svg');
  font-weight: 700;
  font-style: italic;
  font-stretch: normal;
  unicode-range: U+0020-FB03;
}

@font-face {
  font-family: 'Proba Pro';
  src: url('../fonts/Proba Pro Bold.eot');
  src: url('../fonts/Proba Pro Bold.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Proba Pro Bold.woff2') format('woff2'),
  url('../fonts/Proba Pro Bold.woff') format('woff'),
  url('../fonts/Proba Pro Bold.svg#Proba Pro Bold') format('svg');
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
  unicode-range: U+0020-FB03;
}

@font-face {
  font-family: 'Proba Pro';
  src: url('../fonts/Proba Pro Regular.eot');
  src: url('../fonts/Proba Pro Regular.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Proba Pro Regular.woff2') format('woff2'),
  url('../fonts/Proba Pro Regular.woff') format('woff'),
  url('../fonts/Proba Pro Regular.svg#Proba Pro Regular') format('svg');
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  unicode-range: U+0020-FB03;
}

@font-face {
  font-family: 'Proba Pro';
  src: url('../fonts/Proba Pro Extra Light Italic.eot');
  src: url('../fonts/Proba Pro Extra Light Italic.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Proba Pro Extra Light Italic.woff2') format('woff2'),
  url('../fonts/Proba Pro Extra Light Italic.woff') format('woff'),
  url('../fonts/Proba Pro Extra Light Italic.svg#Proba Pro Extra Light Italic') format('svg');
  font-weight: 200;
  font-style: italic;
  font-stretch: normal;
  unicode-range: U+0020-FB03;
}

@font-face {
  font-family: 'Proba Pro';
  src: url('../fonts/Proba Pro Extra Light.eot');
  src: url('../fonts/Proba Pro Extra Light.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Proba Pro Extra Light.woff2') format('woff2'),
  url('../fonts/Proba Pro Extra Light.woff') format('woff'),
  url('../fonts/Proba Pro Extra Light.svg#Proba Pro Extra Light') format('svg');
  font-weight: 200;
  font-style: normal;
  font-stretch: normal;
  unicode-range: U+0020-FB03;
}

@font-face {
  font-family: 'Proba Pro';
  src: url('../fonts/Proba Pro Italic.eot');
  src: url('../fonts/Proba Pro Italic.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Proba Pro Italic.woff2') format('woff2'),
  url('../fonts/Proba Pro Italic.woff') format('woff'),
  url('../fonts/Proba Pro Italic.svg#Proba Pro Italic') format('svg');
  font-weight: 400;
  font-style: italic;
  font-stretch: normal;
  unicode-range: U+0020-FB03;
}

@font-face {
  font-family: 'Proba Pro';
  src: url('../fonts/Proba Pro Light Italic.eot');
  src: url('../fonts/Proba Pro Light Italic.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Proba Pro Light Italic.woff2') format('woff2'),
  url('../fonts/Proba Pro Light Italic.woff') format('woff'),
  url('../fonts/Proba Pro Light Italic.svg#Proba Pro Light Italic') format('svg');
  font-weight: 300;
  font-style: italic;
  font-stretch: normal;
  unicode-range: U+0020-FB03;
}

@font-face {
  font-family: 'Proba Pro';
  src: url('../fonts/Proba Pro Light.eot');
  src: url('../fonts/Proba Pro Light.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Proba Pro Light.woff2') format('woff2'),
  url('../fonts/Proba Pro Light.woff') format('woff'),
  url('../fonts/Proba Pro Light.svg#Proba Pro Light') format('svg');
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  unicode-range: U+0020-FB03;
}

@font-face {
  font-family: 'Proba Pro';
  src: url('../fonts/Proba Pro Medium Italic.eot');
  src: url('../fonts/Proba Pro Medium Italic.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Proba Pro Medium Italic.woff2') format('woff2'),
  url('../fonts/Proba Pro Medium Italic.woff') format('woff'),
  url('../fonts/Proba Pro Medium Italic.svg#Proba Pro Medium Italic') format('svg');
  font-weight: 500;
  font-style: italic;
  font-stretch: normal;
  unicode-range: U+0020-FB03;
}

@font-face {
  font-family: 'Proba Pro';
  src: url('../fonts/Proba Pro Medium.eot');
  src: url('../fonts/Proba Pro Medium.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Proba Pro Medium.woff2') format('woff2'),
  url('../fonts/Proba Pro Medium.woff') format('woff'),
  url('../fonts/Proba Pro Medium.svg#Proba Pro Medium') format('svg');
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  unicode-range: U+0020-FB03;
}

@font-face {
  font-family: 'Proba Pro';
  src: url('../fonts/Proba Pro Regular.eot');
  src: url('../fonts/Proba Pro Regular.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Proba Pro Regular.woff2') format('woff2'),
  url('../fonts/Proba Pro Regular.woff') format('woff'),
  url('../fonts/Proba Pro Regular.svg#Proba Pro Regular') format('svg');
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  unicode-range: U+0020-FB03;
}

@font-face {
  font-family: 'Proba Pro';
  src: url('../fonts/Proba Pro Semi Bold Italic.eot');
  src: url('../fonts/Proba Pro Semi Bold Italic.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Proba Pro Semi Bold Italic.woff2') format('woff2'),
  url('../fonts/Proba Pro Semi Bold Italic.woff') format('woff'),
  url('../fonts/Proba Pro Semi Bold Italic.svg#Proba Pro Semi Bold Italic') format('svg');
  font-weight: 600;
  font-style: italic;
  font-stretch: normal;
  unicode-range: U+0020-FB03;
}

@font-face {
  font-family: 'Proba Pro';
  src: url('../fonts/Proba Pro Semi Bold.eot');
  src: url('../fonts/Proba Pro Semi Bold.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Proba Pro Semi Bold.woff2') format('woff2'),
  url('../fonts/Proba Pro Semi Bold.woff') format('woff'),
  url('../fonts/Proba Pro Semi Bold.svg#Proba Pro Semi Bold') format('svg');
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  unicode-range: U+0020-FB03;
}

@font-face {
  font-family: 'Proba Pro';
  src: url('../fonts/Proba Pro Thin Italic.eot');
  src: url('../fonts/Proba Pro Thin Italic.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Proba Pro Thin Italic.woff2') format('woff2'),
  url('../fonts/Proba Pro Thin Italic.woff') format('woff'),
  url('../fonts/Proba Pro Thin Italic.svg#Proba Pro Thin Italic') format('svg');
  font-weight: 100;
  font-style: italic;
  font-stretch: normal;
  unicode-range: U+0020-FB03;
}

@font-face {
  font-family: 'Proba Pro';
  src: url('../fonts/Proba Pro Thin.eot');
  src: url('../fonts/Proba Pro Thin.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Proba Pro Thin.woff2') format('woff2'),
  url('../fonts/Proba Pro Thin.woff') format('woff'),
  url('../fonts/Proba Pro Thin.svg#Proba Pro Thin') format('svg');
  font-weight: 100;
  font-style: normal;
  font-stretch: normal;
  unicode-range: U+0020-FB03;
}
