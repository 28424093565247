.loader {
  width: $loader-size;
  height: $loader-size;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -$loader-size / 2;
  margin-top: -$loader-size / 2;
  text-indent: -9999em;
  z-index: $zindex-modal-backdrop + 1;

  &:after,
  &:before {
    position: absolute;
    left: 0;
    top: 0;
    content: "";
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }

  &:after {
    border: $loader-weight solid transparent;
    transform: translateZ(0);
    animation: rotate360 $loader-speed infinite linear;
  }

  &--light {
    &:before {
      border: $loader-weight solid rgba( $loader-light-color, 0.2 );
    }

    &:after {
      border-left: $loader-weight solid $loader-light-color;
    }
  }

  &--dark {
    &:before {
      border: $loader-weight solid rgba($loader-dark-color, 0.2);
    }

    &:after {
      border-left: $loader-weight solid $loader-dark-color;
    }
  }
}

.loading {
  position: relative;

  &:before {
    content: '';

    @extend %oi-backdrop;
  }

  &--absolute {
    &:before {
      position: absolute;
    }
  }
}

@keyframes rotate360 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/*.section {
  position: relative;
  width: 100%;
  height: 400px;
  margin: auto;

  &--primary {
    background: $brand-primary;
  }
}
*/
