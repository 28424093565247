@mixin button-hovers($color) {
  transition: 0.2s background ease, 0.2s border ease, 0.2s color ease, 0.2s box-shadow ease;

  &:hover,
  &:focus,
  &:active {
    background-color: #fff !important;
    border-color: $color !important;
    color: $color !important;
  }

  &:active {
    box-shadow: inset 0 0 0 4px $color;
  }
}

@keyframes scroll-down {
    0% { transform: translateY(0); opacity: 0; }
    10% { transform: translateY(0); opacity: 1; }
    80% { transform: translateY(10px); opacity: 1; }
    90% { transform: translateY(10px); opacity: 0; }
    100% { transform: translateY(0); opacity: 0; }
}

@keyframes scroll-down-reversed {
    0% { transform: translateY(0) rotate(180deg); opacity: 0; }
    10% { transform: translateY(0) rotate(180deg); opacity: 1; }
    80% { transform: translateY(10px) rotate(180deg); opacity: 1; }
    90% { transform: translateY(10px) rotate(180deg); opacity: 0; }
    100% { transform: translateY(0) rotate(180deg); opacity: 0; }
}

// Global Backdrop
%oi-backdrop {
  backface-visibility: hidden;
  background-color: $modal-backdrop-bg;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: $modal-backdrop-opacity;
  position: fixed;
  top: 0;
  z-index: $zindex-modal-backdrop;
}

%oi-offcanvas-transition {
  transition: transform .33s cubic-bezier(0.33,0,.2,1),top .6s cubic-bezier(0.33,0,.2,1),opacity .5s cubic-bezier(0.33,0,.2,1);
}

%oi-section-box-shadow {
  box-shadow: 0 22px 14px 0 rgba(0, 0, 0, 0.05);
}

%oi-white-shadowed-box {
  border-color: #e5e5e5;
  background-color: #fff;

  @extend .img-thumbnail;
  @extend .img-box-shadow;
}

@mixin mb-down($breakpoint) {
  @include media-breakpoint-down($breakpoint) {
    @content;
  }
}

@mixin mb-up($breakpoint) {
  @include media-breakpoint-up($breakpoint) {
    @content;
  }
}


@mixin mb-only($breakpoint) {
  @include media-breakpoint-only($breakpoint) {
    @content;
  }
}
