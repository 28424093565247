.main {
  ul {
    &:not(.list-inline):not(.nav):not(.list-group):not(.products):not(.pagination):not(.navbar-nav):not(.page-header__menu):not(.list--untouched) {
      list-style: none;
      padding-left: 0;

      li {
        font-size: $lead-font-size;
        line-height: 1.25;
        margin-bottom: 20px;
        padding-left: 20px;
        position: relative;

        ul {
          margin-top: 20px;

          li {
            margin-bottom: 10px;
          }
        }

        &:before {
          background-color: $brand-secondary;
          border-radius: 100%;
          content: '';
          display: block;
          position: absolute;
          left: 0;
          top: 6px;
          width: 8px;
          height: 8px;
        }
      }
    }

    &.list-unstyled:not(.page-header__menu):not(.list--untouched) {
      li {
        padding-left: 0 !important;
        margin-bottom: 10px !important;

        &:before {
          display: none !important;
        }

        &:last-child {
          margin-bottom: 0 !important;
        }
      }
    }
  }

  ol {
    padding-left: 15px;

    li {
      font-size: $lead-font-size;
      line-height: 1.25;
      margin-bottom: 20px;
      padding-left: 10px;
    }
  }
}

.list {
  &--arrowed {
    li {
      margin-bottom: 15px;
      padding-left: 35px;
      position: relative;
      text-transform: uppercase;

      &:last-child {
        margin-bottom: 0;
      }

      &:after {
        content: '';
        position: absolute;
        left: 0;
        top: 50%;
        margin-top: -12px;
        transform: rotate(270deg);

        @extend %icon__24x24_arrow-grey;
      }

      &,
      a {
        color: $text-color;
        text-decoration: none;
      }

      a {
        &:hover {
          color: $brand-default;
          text-decoration: none;
        }
      }

      &:hover,
      &.active {
        &,
        a {
          color: $brand-default;
          font-weight: 500;
        }

        a {
          &:after {
            @extend %icon__24x24_arrow-blue;
          }
        }

        &:after {
          @extend %icon__24x24_arrow-blue;
        }
      }
    }
  }
}

.list-inline {
  &--separated {
    display: flex;

    .list-inline-item {
      line-height: 1.25;
      position: relative;

      &:not(:last-child) {
        &:after {
          background-color: #fff;
          content: '';
          position: absolute;
          right: (0 - ($list-inline-padding / 2 ));
          top: 50%;
          margin-top: -10px;
          width: 1px;
          height: 20px;
        }
      }
    }
  }

  .list-inline-item {
    &--active {
      font-weight: bold;
    }
  }
}

.list-group {
  .list-group-item {
    font-size: 21px;
    font-weight: 300;
    line-height: 25px;
    text-transform: uppercase;

    @include mb-down(md) {
      font-size: 18px;
      padding: 20px 25px;
    }
  }
}
