// Search form
.search-form {
  @extend .form-inline;
}
.search-form label {
  font-weight: normal;
  @extend .form-group;
}
.search-form .search-field {
  @extend .form-control;
}
.search-form .search-submit {
  @extend .btn;
  @extend .btn-secondary;
}

.form-group {
  padding-top: 15px;
  position: relative;

  &.html-wrap {
    .form-control-label {
      display: none;
    }

    &:after {
      display: none;
    }
  }

  &.form-group-inverse {
    color: #fff;

    select.form-control {
      background-image: url("../images/icon__16x16_dropdown-white.svg");

      &:hover {
        background-image: url("../images/icon__16x16_dropdown-white.svg");
      }
    }

    .custom-control {
      .custom-control-indicator {
        background-size: $custom-control-indicator-bg-size;
        background-image: url("../images/icon__16x16_check-white.svg");
        box-shadow: inset 0 0 0 2px #fff;
      }

      .custom-control-input:checked ~ .custom-control-indicator {
        background-image: url("../images/icon__16x16_check.svg");
        background-color: #fff;
        background-size: $custom-control-indicator-bg-size;
        box-shadow: inset 0 0 0 2px #fff;
      }
    }

    label {
      text-transform: none;
    }

    .form-control {
      color: #fff;
      border-bottom-color: #fff;
    }

    &.form-group-dirty {
      &:after {
        background-color: $gray;
      }
    }
  }

  &.form-group-condensed {
    .custom-control {
      margin-right: 20px;
    }
  }

  &.form-group-rangeslider {
    .form-control-rangeslider {
      position: relative;
      z-index: 3;
    }
  }

  .form-control {
    border: 0;
    border-bottom: 1px solid $gray;
    font-family: $font-family-sans-serif;
    padding-left: 0;
    padding-right: 0;
    position: relative;
    box-shadow: none;
    z-index: 1;
  }

  select.form-control {
    background-image: url("../images/icon__16x16_dropdown.svg");
    background-repeat: no-repeat;
    background-position: 95% center; // compatibility
    background-position: calc(100% - 20px) center;
    appearance: none;

    &:hover {
      background-image: url("../images/icon__16x16_dropdown-hover.svg");
    }

    option {
      color: $text-color;
      padding-left: 0;
    }
  }

  .form-control-label {
    cursor: text;
    font-weight: normal;
    left: 0;
    line-height: 1;
    position: absolute;
    margin: 0;
    z-index: 0;
    transition: 0.2s all ease;
    top: 27px;
  }

  &:after {
    bottom: 0;
    display: block;
    content: '';
    position: absolute;
    left: 0;
    height: 1px;
    width: 0;
    z-index: 2;
    transition: 0.4s width ease;
  }

  &.form-group-hover:hover,
  &.form-group-focused {
    &:after {
      width: 100%;
    }
  }

  &:hover:not(.has-danger),
  &:hover:not(.has-warning),
  &:hover:not(.has-success) {
    &:after {
      background-color: $brand-primary;
    }
  }

  &.form-group-focused {
    &:hover:not(.has-danger),
    &:hover:not(.has-warning),
    &:hover:not(.has-success) {
      &:after {
        background-color: $brand-default;
      }
    }
  }

  &.form-group-focused,
  &.form-group-dirty,
  &.has-danger,
  &.has-warning,
  &.has-success {
    .form-control-label {
      font-size: 14px;
      top: 5px;
    }

    &:after {
      background-color: $brand-primary;
    }
  }

  &.has-danger {
    &:after {
      background-color: $brand-danger;
    }

    .form-control-label,
    .form-control {
      border-color: $brand-danger !important;
      color: $text-color;
      box-shadow: none !important;
    }

    .form-control {
      color: $brand-danger;
    }
  }

  &.has-success {
    &:after {
      background-color: $brand-success;
    }

    .form-control-label,
    .form-control {
      border-color: $brand-success !important;
      color: $text-color;
      box-shadow: none !important;
    }

    .form-control {
      color: $brand-success;
    }
  }

  &.has-warning {
    &:after {
      background-color: $brand-warning;
    }

    .form-control-label,
    .form-control {
      border-color: $brand-warning !important;
      color: $text-color;
      box-shadow: none !important;
    }

    .form-control {
      color: $brand-warning;
    }
  }
}

.help-block {
  font-size: 14px;
  line-height: 1.1;
  margin-top: 15px;
}

.has-success {
  .form-control {
    @extend .form-control-success;
  }
}

.has-danger {
  .form-control {
    @extend .form-control-danger;
  }
}

.has-warning {
  .form-control {
    @extend .form-control-warning;
  }
}

.custom-control {
  &--rounded {
    padding-left: 42px;
    min-height: 32px;
    line-height: 32px;

    .custom-control-indicator {
      border-radius: 50%;
      width: 32px;
      height: 32px;
    }
  }
}

.custom-control-indicator {
  box-shadow: $custom-control-indicator-box-shadow;
}

.custom-radio {
  .custom-control-indicator {
    background-image: $custom-radio-checked-icon;
    background-size: 0 0;
  }
}

.custom-radio,
.custom-checkbox {
  .custom-control-indicator {
    transition: background-size .15s;
  }

  .custom-control-input:checked ~ .custom-control-indicator {
    background-size: $custom-control-indicator-bg-size;
    box-shadow: $custom-control-focus-indicator-box-shadow;
  }
}

.nf-form-content {
  .nf-field-container {
    margin-bottom: $form-group-margin-bottom;
  }

  .form-group {
    margin-bottom: 0;

    &.nf-error {
      @extend .has-danger;

      .form-control {
        @extend .form-control-danger;
      }

      .ninja-forms-field {
        border-left: 0;
        border-top: 0;
        border-right: 0;
      }
    }
  }

  .nf-after-field {
    .nf-error {
      @extend .has-danger;
    }
  }
}

