.page {
  &__section {
    .page-template-template-about & {
      &:nth-child(odd) {
        background-color: $gray-light;

        .img-thumbnail {
          border-color: #e5e5e5;
        }
      }
    }

    .page-template-template-technologies & {
      &--type_architecture_typology {
        background-color: #f5f5f5;

        @extend .pt-4;
        @extend .pt-md-5;

        .img-thumbnail {
          border-color: #e5e5e5;
          z-index: 1;
        }

        .section__image {
          position: relative;
          z-index: 2;
        }
      }

      &--type_architecture_natural_materials {
        @extend .pt-4;
        @extend .pt-md-5;
      }
    }

    &--type_about_architecture {
      .section__images-wrap {
        @include mb-up(md) {
          display: inline-block;
          margin-bottom: 147px;
        }
      }

      .section__image--small {
        @include mb-up(md) {
          left: 50%;
          position: absolute;
          top: 50%;
          margin-left: 50px;
          margin-top: 74px;
        }

        @include mb-up(xl) {
          margin-left: 74px;
          margin-top: 74px;
        }

        @include mb-down(sm) {
          border: 0;
          box-shadow: none;
          margin-top: 10px;
        }
      }

      .section__square {
        background-color: #fff;
        border-color: #e5e5e5;
        left: 50%;
        margin-left: 8px;
        margin-top: -46px;
        position: absolute;
        top: 100%;
        width: 92px;
        height: 92px;

        @include mb-up(xl) {
          margin-left: 26px;
        }

        @include mb-down(sm) {
          top: 50%;
          margin-left: -46px;
        }
      }
    }

    &--type_about_technologies {
      .section__images {
        position: relative;
      }

      .section__images-wrap {
        align-items: center;
        display: flex;
        position: absolute;
        top: 0;
        bottom: 0;
        right: 65px;
        z-index: 0;

        @include mb-down(sm) {
          position: relative;
          right: auto;
          top: auto;
          bottom: auto;
        }
      }

      .section__gallery {
        position: relative;
        z-index: 1;
        align-items: flex-start;
        flex-direction: column;

        @include mb-down(sm) {
          flex-direction: row;
        }

        @extend .row;

        img {
          margin-bottom: 30px;

          @include mb-down(sm) {
            margin-bottom: $grid-gutter-width-base;
            width: 100%;
            border: 0;
            box-shadow: none;

            @include make-col-ready();
            @include make-col(4);
          }

          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }

    &--type_architecture_environment {
      h3 {
        @include mb-down(sm) {
          font-size: $font-size-h6;
        }
      }
    }

    &--type_about_construction,
    &--type_technologies_smart_home {
      .section__images-wrap {
        max-width: 600px;
        margin-left: auto;
      }

      .section__gallery {
        position: relative;

        &:after {
          content: '';
          position: absolute;
          width: 92px;
          height: 92px;
          margin-top: -46px;
          top: 50%;
          left: 50%;
          margin-left: -46px;

          @extend %oi-white-shadowed-box;
        }
      }
    }

    &--type_about_developer {
      .container {
        z-index: 1;
      }

      .section__background {
        position: relative;

        &:after {
          bottom: 0;
          right: 0;
          left: 0;
          top: 0;
          content: '';
          background-image: linear-gradient(to bottom, rgba(251, 251, 251, 0), #f5f5f5);
          position: absolute;
          z-index: 0;
        }
      }

      .section__gallery {
        position: relative;

        &:after,
        &:before {
          background-color: $brand-default;
          content: '';
          position: absolute;
          width: 92px;
          height: 92px;
          z-index: -1;
        }

        &:before {
          bottom: -46px;
          left: -46px;
        }

        &:after {
          top: -46px;
          right: -46px;
        }
      }
    }

    &--type_architecture_typology {
      .section__gallery {
        position: relative;

        @include mb-up(md) {
          margin-left: 50px;
          display: inline-block;
        }

        @include mb-up(xl) {
          margin-left: 75px;
        }

        &:before,
        &:after {
          content: '';
          display: none;
          position: absolute;
          width: 200px;
          height: 200px;
          z-index: -1;

          @include mb-up(xl) {
            width: 230px;
            height: 230px;
          }

          @include mb-up(sm) {
            display: block;
          }

          @extend %oi-white-shadowed-box;
        }

        &:before {
          left: -50px;
          top: -50px;

          @include mb-up(xl) {
            left: -75px;
            top: -75px;
          }
        }

        &:after {
          right: -50px;
          bottom: -50px;

          @include mb-up(xl) {
            right: -75px;
            bottom: -75px;
          }
        }
      }
    }

    &--type_architecture_gallery {
      .section__image--small-left,
      .section__image--small-right,
      .section__image--medium {
        position: relative;

        img {
          position: relative;
          z-index: 1;

          @include mb-down(sm) {
            width: 100%;
          }
        }

        &:after {
          content: '';
          position: absolute;
          width: 64px;
          height: 64px;
          z-index: 2;

          @extend %oi-white-shadowed-box;
        }
      }

      .section__image--small-left {
        &:after {
          right: -32px;
          top: -32px;

          @include mb-down(sm) {
            top: -42px;
          }
        }
      }

      .section__image--small-left,
      .section__image--small-right {
        &:after {
          @include mb-down(sm) {
            top: auto;
            bottom: -42px;
            right: auto;
            left: 50%;
            margin-left: -32px;
          }
        }
      }

      .section__image--small-right {
        &:after {
          right: -32px;
          bottom: -40px;
        }
      }

      .section__image--medium {
        &:after {
          left: 50%;
          margin-left: -32px;
          bottom: -32px;

          @include mb-down(sm) {
            bottom: auto;
            top: -42px;
          }
        }
      }
    }

    &--type_architecture_natural_materials {
      .section__gallery {
        position: relative;
        max-width: 450px;

        @include mb-up(md) {
          margin-right: 50px;
        }

        @include mb-up(xl) {
          margin-right: 75px;
        }

        &:before,
        &:after {
          content: '';
          display: none;
          position: absolute;
          width: 200px;
          height: 200px;
          z-index: -1;

          @include mb-up(xl) {
            width: 230px;
            height: 230px;
          }

          @include mb-up(sm) {
            display: block;
          }

          @extend %oi-white-shadowed-box;
        }

        &:before {
          right: -50px;
          top: -50px;

          @include mb-up(xl) {
            right: -75px;
            top: -75px;
          }
        }

        &:after {
          left: -50px;
          bottom: -50px;

          @include mb-up(xl) {
            left: -75px;
            bottom: -75px;
          }
        }
      }
    }

    &--type_interior_interior {
      .gallery__image {
        position: relative;

        &:first-child {
          &:before {
            display: none;
          }

          &:before,
          &:after {
            content: '';
            position: absolute;
            width: 92px;
            height: 92px;
            z-index: 2;

            bottom: -56px;
            left: 50%;
            margin-left: -46px;

            @extend %oi-white-shadowed-box;
            @extend .img-thumbnail;
          }

          @include mb-up(sm) {
            &:before {
              display: block;
              bottom: 21%;
              left: -38px;
              margin-left: 0;
            }

            &:after {
              display: block;
              left: auto;
              bottom: auto;
              top: 50px;
              right: -46px;
              margin-left: 0;
            }
          }
        }

        &:last-child {
          img {
            &:first-child {
              @include mb-up(sm) {
                margin-bottom: 15px !important;
              }

              @include mb-up(md) {
                margin-bottom: 20px !important;
              }
            }
          }

          &:before {
            content: '';
            position: absolute;
            width: 92px;
            height: 92px;
            z-index: 2;

            top: 50%;
            left: 50%;
            margin-left: -46px;
            margin-top: -46px;

            @include mb-up(sm) {
              top: 50%;
              margin-top: -46px;
              margin-left: 0;
              left: auto;
              right: 40px + $grid-gutter-width-base;
            }

            @extend %oi-white-shadowed-box;
            @extend .img-thumbnail;
          }
        }
      }
    }

    &--type_interior_entrance {
      .gallery__image {
        position: relative;

        &:after {
          content: '';
          position: absolute;
          width: 92px;
          height: 92px;
          z-index: 2;

          @extend %oi-white-shadowed-box;
          @extend .img-thumbnail;
        }

        &:first-child {
          &:after {
            right: -46px;
            top: 50px;

            @include mb-down(sm) {
              top: 50%;
              margin-top: -46px;
            }
          }
        }

        &:last-child {
          &:after {
            right: -36px;
            bottom: 50px;

            @include mb-down(sm) {
              display: none;
            }
          }
        }
      }
    }

    &--type_interior_interior,
    &--type_interior_virtual_tour,
    &--type_interior_entrance {
      h2 {
        @extend .h1;
      }
    }

    &--type_interior_virtual_tour {
      p {
        @extend .lead;
      }
    }

    &--type_construction_photo_report {
      background-color: $gray-light;

      .gallery__item {
        &:nth-child(3n+5) {
          @extend .offset-md-3;
        }
      }
    }

    &--type_construction_photo_report,
    &--type_construction_air_survey {
    }

    &--type_apartments_interior_elegance,
    &--type_apartments_interior_harmony {
      .section__content {
        z-index: 2;

        > div {
          flex-direction: column;
        }
      }

      .images__description {
        position: absolute;
        top: 100%;
      }

      .content__text {
        background-color: #fff;
        border: 15px solid #e5e5e5;
        padding: 45px 35px;
        position: absolute;
        top: 30px;
        left: $grid-gutter-width-base / 2;
        right: -90px;

        @include mb-down(lg) {
          padding: 25px;
        }

        @include mb-down(md) {
          position: relative;
          right: auto;
          top: auto;
          left: auto;
          bottom: auto;
        }

        h2 {
          margin-bottom: 20px;
        }

        p:last-child {
          margin-bottom: 0;
        }
      }

      .content__navigation {
        @include mb-up(lg) {
          position: absolute;
          bottom: 0;
          left: 10px;
          right: 10px;
        }
      }

      .section__images {
        z-index: 1;
      }
    }

    &--type_apartments_interior_elegance {
      .images__description {
        left: $grid-gutter-width-base / 2;
      }
    }

    &--type_apartments_interior_harmony {
      .content__text {
        right: $grid-gutter-width-base / 2;
        left: -90px;

        @include mb-down(md) {
          right: auto;
          left: auto;
        }
      }

      .section__legend {
        left: 30px + ($grid-gutter-width-base / 2);
        right: auto;

        @include mb-down(sm) {
          right: auto;
          left: auto;
        }
      }

      .images__description {
        right: $grid-gutter-width-base / 2;
      }
    }

    &--type_apartments_interior_materials {
      @include mb-down(sm) {
        .display-1 {
          font-size: $font-size-h1;
        }
      }
    }

    &--type_apartments_interior_products {

    }

    &--type_environment_marina_lifestyle {
      h2 {
        font-size: $font-size-h1;
      }

      .section__image-wrap {
        position: relative;

        &--left-1,
        &--left-2,
        &--right-3 {
          &:after {
            content: '';
            position: absolute;
            width: 92px;
            height: 92px;
            z-index: 2;

            @extend %oi-white-shadowed-box;
            @extend .img-thumbnail;
          }
        }

        &--left-1 {
          &:after {
            left: ($grid-gutter-width-base / 2) + 50px;
            top: -46px;

            @include mb-down(sm) {
              left: 50%;
              margin-left: -46px;
              top: auto;
              bottom: -36px;
              box-shadow: none;
            }
          }
        }

        &--left-2 {
          &:after {
            display: none;

            @include mb-down(sm) {
              display: block;
              left: 50%;
              margin-left: -46px;
              bottom: -36px;
            }
          }
        }

        &--right-3 {
          &:after {
            right: ($grid-gutter-width-base / 2) + 50px;
            bottom: -36px;

            @include mb-down(sm) {
              left: 50%;
              margin-left: -46px;
              top: auto;
              bottom: -36px;
              box-shadow: none;
            }
          }
        }
      }
    }

    &--type_environment_harmony {
      h2 {
        font-size: $font-size-h1;
      }

      .section__image-wrap {
        position: relative;

        &--small-left,
        &--small-right,
        &--big {
          &:after {
            content: '';
            position: absolute;
            width: 92px;
            height: 92px;
            z-index: 2;

            @extend %oi-white-shadowed-box;
            @extend .img-thumbnail;

            @include mb-down(sm) {
              box-shadow: none;
            }
          }
        }

        &--small-left {
          &:after {
            left: -46px;
            top: 50px;

            @include mb-down(sm) {
              left: 50%;
              top: auto;
              bottom: -56px;
              margin-left: -46px;
            }
          }
        }

        &--small-right {
          &:after {
            bottom: 50px;
            left: -46px;

            @include mb-down(sm) {
              left: 50%;
              top: auto;
              bottom: -56px;
              margin-left: -46px;
            }
          }
        }

        &--big {
          &:after {
            left: ($grid-gutter-width-base / 2) + 60px;
            top: -46px;

            @include mb-down(sm) {
              display: none;
            }
          }
        }
      }
    }

    &--type_environment_active_life {
      h2 {
        font-size: $font-size-h1;
      }

      @include mb-down(sm) {
        img {
          width: 100%;
        }
      }

      .section__image-wrap {
        &--small-right,
        &--medium,
        &--big {
          &:after {
            content: '';
            position: absolute;
            width: 92px;
            height: 92px;
            z-index: 2;

            @extend %oi-white-shadowed-box;
            @extend .img-thumbnail;

            @include mb-down(sm) {
              box-shadow: none;
            }
          }
        }

        &--small-right {
          &:after {
            top: 90px;
            right: -46px;

            @include mb-down(sm) {
              top: -56px;
              margin-right: 50%;
            }

            @include mb-only(sm) {
              top: -46px;
            }
          }
        }

        &--medium {
          &:after {
            top: -56px;
            left: 50%;
            margin-left: -46px;
          }
        }

        &--big {
          &:after {
            bottom: -46px;
            left: 50%;
            margin-left: -46px;

            @include mb-down(sm) {
              top: -56px;
              margin-right: 50%;
              bottom: auto;
            }
          }
        }
      }

      p {
        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    &--type_sales_information_two_column_content {
      .section__content {
        h2,
        h3,
        h4 {
          color: $brand-default;
          font-weight: 300;
        }

        p {
          @extend .lead;
        }

        ul {
          li {
            font-weight: 500;
          }
        }
      }
    }

    &--type_sales_information_contact_map {
      .section__image-wrap {
        position: relative;

        &:before,
        &:after {
          content: '';
          position: absolute;
          width: 50px;
          height: 50px;
          z-index: 2;

          @extend %oi-white-shadowed-box;
          @extend .img-thumbnail;
        }

        &:before {
          left: 40px;
          top: -25px;
        }

        &:after {
          bottom: 40px;
          right: -25px;
        }
      }

      .list-inline {
        .list-inline-item {
          font-size: 18px;

          &:not(:last-child) {
            margin-right: 35px;
          }

          a {
            color: #fff;
          }
        }
      }

      .section__map {
        @include mb-up(lg) {
          left: calc(50vw - 240px);
        }

        @include mb-up(md) {
          bottom: 0;
          left: calc(50vw - 180px);
          position: absolute;
          right: 0;
          top: 0;
        }

        @include mb-down(sm) {
          height: 350px;

          @include make-col-ready();
          @include make-col(12);
        }
      }
    }
  }
}

.section {
  &__content {
    @include mb-down(sm) {
      .display-1 {
        font-size: $font-size-h1;
      }
    }

    &--without-header {
      margin-top: $grid-gutter-width-base / 2;

      @include media-breakpoint-only(sm) {
        margin-top: $grid-gutter-width-base;
      }

      @include mb-up(md) {
        margin-top: 190px;
      }
    }
  }

  &__background {
    background-repeat: no-repeat;
    background-position: center bottom;
    background-size: cover;
  }

  &__images {
    position: relative;
  }

  &__images-wrap {
    position: relative;

    @include mb-up(md) {
      display: inline-block;
    }
  }

  &__image {
    @include mb-down(sm) {
      width: 100%;
    }
  }

  &__legend {
    position: absolute;
    right: 30px + ($grid-gutter-width-base / 2);
    bottom: -30px;
    z-index: 2;

    @include mb-down(sm) {
      position: relative;
      right: auto;
      bottom: auto;
      margin-top: 30px;
    }

    li {
      font-size: 20px;
      line-height: 1.2;

      & + li {
        margin-top: 20px;
      }
    }

    a {
      color: #fff;

      &:hover {
        text-decoration: none;
      }
    }
  }

  &__gallery {
    position: relative;

    @include mb-up(md) {
      margin-bottom: 0 - $grid-gutter-width-base;
    }

    .gallery__description,
    .gallery__item {
      @include mb-up(md) {
        max-height: 315px;
      }
    }

    .gallery__title {
      word-break: break-word;
    }

    .gallery__item {
      .gallery__image {
        z-index: 1;
        position: relative;
      }
    }

    .gallery__overlay {
      justify-content: flex-end;
      background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 50%, #282828 100%);
      bottom: 0;
      display: flex;
      flex-direction: column;
      left: $grid-gutter-width-base / 2;
      position: absolute;
      right: $grid-gutter-width-base / 2;
      top: 0;
      z-index: 2;
    }
  }
}

.developer {
  &__logo {
    img {
      max-width: 250px;
    }
  }
}

.images {
  &__description {

  }
}
