.img-box-shadow {
  @extend %oi-section-box-shadow;
}

.img-thumbnail {
  &--simple {
    border: 4px solid #f5f5f5;
  }
}

.gallery {
  @include make-row();

  &.gallery-columns-3 {
    .gallery-item {
      @extend .col-md-4;
    }
  }

  .gallery-item {
    img {
      @extend .img-fluid;

      @include mb-down(sm) {
        width: 100%;
      }
    }
  }

  a {
    display: block;
  }
}
