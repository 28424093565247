.btn {
  padding: 16px 40px 14px;
  text-transform: uppercase;
  line-height: 1.1;

  @include media-breakpoint-down(md) {
    padding: 10px 25px;
  }

  @include media-breakpoint-down(sm) {
    padding: 10px 25px;
  }
}

.btn-secondary {
  @include button-hovers($brand-secondary);
}

.btn-default {
  @include button-variant(#fff, $brand-default, $brand-default);
  @include button-hovers($brand-default);
}

.btn-white {
  @include button-variant($brand-default, #fff, #fff);
  @include button-hovers($brand-default);
}

.btn-primary {
  @include button-hovers($brand-primary);
}

.btn-info {
  @include button-hovers($brand-info);
}

.btn-success {
  @include button-hovers($brand-success);
}

.btn-warning {
  @include button-hovers($brand-warning);
}

.btn-danger {
  @include button-hovers($brand-danger);
}

.link {
  &__back {
    color: $text-color;
    display: inline-block;
    padding: 10px 10px 10px 35px;
    position: relative;
    line-height: 24px;

    &:before {
      content: "";
      position: absolute;
      left: 0;
      top: 50%;
      margin-top: -12px;
      bottom: 0;
      transform: rotate(90deg);
      transition: all 0.2s linear;

      @extend %icon__24x24_arrow-blue;
    }

    &:hover,
    &:active {
      color: $brand-default;
      text-decoration: none;

      &:before {
        left: -5px;
      }
    }
  }
}

.btn-show-video {
  margin-right: 20px;
  background-color: transparent;
  border-color: $btn-default-color;
  color: $btn-default-color;
  font-size: 14px;
  padding: 12px 20px;
  .page-header-inversed & {
    border-color: $text-color;
    color: $text-color;
  }
  &:active, 
  &:hover,
  &:focus {
    background-color: $btn-default-color;
    color: $gray-base;
    border-color: $btn-default-color;
    .page-header-inversed & {
      background-color: $text-color;
      border-color: $text-color;
      color: $btn-default-color;
    }
  }
}
