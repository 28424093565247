.content-info {
  background-color: $gray-light;
  color: $gray-text;
  padding-bottom: 50px;
  padding-top: 50px;



  @include mb-down(sm) {
    padding-top: $grid-gutter-width-base;
    padding-bottom: $grid-gutter-width-base;
  }

  &--dark {
    background-color: $text-color;

    .icon__24x24_facebook {
      //opacity: 0.4;
    }
  }

  p {
    margin-bottom: 0.5rem;

    &:last-child {
      margin-bottom: 0;
    }
  }

  a {
    color: $gray-text;

    &:hover,
    &:focus,
    &:active {
      text-decoration: none;
    }
  }
}
