.mapify-holder{
	position: relative;
	display: inline-block;
	font-size: 0px;
	max-width: 100%;/* Make the image responsive */
	z-index:1;
	
	-webkit-touch-callout: none;
	
	-webkit-user-select: none;  /* Chrome all / Safari all */
    -moz-user-select: none;     /* Firefox all */
    -ms-user-select: none;      /* IE 10+ */
}

.mapify-imgHolder{
	position:relative; 
	z-index:1;
}

.mapify-holder *,
.mapify-GPU{
	-webkit-transform: translate3d(0,0,0);
    -moz-transform: translate3d(0,0,0);
    -ms-transform: translate3d(0,0,0);
    -o-transform: translate3d(0,0,0);
    transform: translate3d(0,0,0);
}

/* Make the image responsive */
.mapify-imgHolder .mapify{
	max-width: 100%;
	height: auto;
}

.mapify-img{
	position: absolute;
	top:0px;
	left: 0px;
	width: 100%;
	height: 100%;
	z-index: -2;
}
.mapify-svg{
	position: absolute;
	top:0px;
	left: 0px;
	width: 100%;
	height: 100%;
	z-index: -2;
}

/* Styles for the hilight effect */

.mapify-polygon{
	transition: all 0.5s;
	fill:transparent;
	stroke: transparent;
	stroke-width: 0;
	
	/* do not use css transform translate3d, otherwise it starts lagging in iOs when panning the map*/
}
.mapify-hover{
	fill:#09f;
}

/**/

.mapify-popOver{
	
	color: #000;
	position: absolute;
	top:0px;
	left: 0px;
	
	padding:20px 30px;
	width: 260px;
	background: #fff;
	box-shadow: rgba(0,0,0,0.15) 0 0 0 2px;
	z-index: 999;
	-webkit-transform: translateY(-15px);
	transform: translateY(-15px);
	border-radius: 5px;
	text-align: center;
	height: auto;
	
	box-sizing: border-box;
	-moz-box-sizing: border-box;
	
	font-size: 14px;
	
	z-index: -1;
	
	opacity: 0;
	
	/* now declared in the plugin options 
	transition: all 0.5s;
	*/
}
.mapify-popOver .mapify-popOver-arrow{
	content: "";
	width: 15px; 
	height: 15px;
	
	/* now declared in the plugin options 
	transition: margin 0.5s;
	*/

	z-index: -2;
	
	margin-top: -3px;
	
	box-shadow: inset #fff 0 0 0 100px;
	
	border-top: solid transparent 2px;
	border-left: solid transparent 2px;
	
	border-right: solid rgba(0,0,0,0.15) 2px;
	border-bottom: solid rgba(0,0,0,0.15) 2px;
	
	position: absolute;
	top:100%;
	left: 50%;
	-webkit-transform: translateX(-15px) rotate(45deg) translateY(-50%);
	transform: translateX(-15px) rotate(45deg) translateY(-50%);
}
.mapify-popOver.mapify-bottom .mapify-popOver-arrow{
	top:auto;
	bottom: 100%;
	margin-top: auto;
	margin-bottom: -3px;
	
	border-bottom: solid transparent 2px;
	border-right: solid transparent 2px;
	
	border-top: solid rgba(0,0,0,0.15) 2px;
	border-left: solid rgba(0,0,0,0.15) 2px;
	
	-webkit-transform: rotate(45deg) translateY(50%);
	transform: rotate(45deg) translateY(50%);
}

.mapify-popOver.mapify-visible{
	
	/* Showing the popover */
	opacity: 1;
	-webkit-transform: translateY(0px);
	transform: translateY(0px);
}

.modal-floor1, 
.modal-floor2, 
.modal-floor3, 
.modal-floor4 {
  .mapify-popOver {
    display: none;   
  }
}

.parking-popover {
  h3 {
    color: $brand-default;
    margin: 0;
  }
  h1, h2, h3, h4, p {
    margin-bottom: 5px;
  }
}