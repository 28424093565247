.card {
  img {
    width: 100%;
    height: auto;
  }

  .card-block {
    padding: $card-spacer-y $card-spacer-x;

    p {
      &:last-child {
        margin-bottom: 0;
      }
    }

    a {
      color: $brand-default;
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }
  }
}
