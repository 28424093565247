body {
  &.single-post,
  &.page-template-template-pricelist,
  &.single-oi_apartments {
    background-color: $gray-light;
  }
}

html {
  @include media-breakpoint-down(sm) {
    font-size: 14px;
  }
}

.wrap {
  overflow: hidden;

  @extend %oi-offcanvas-transition;
}

.cc-window.cc-invisible {
  pointer-events: none;
}
