.teaser-popup {
  background-color: #F9F7F5;
  position: fixed;
  z-index: $zindex-fixed;
  transition: $transition-base;
  opacity: 1;

  @extend %text-font;

  &.teaser-popup--closed {
    pointer-events: none;
    opacity: 0;

    @include media-breakpoint-up(md) {
      transform: translateY(-50%) translateX(25%);
    }

    @include media-breakpoint-down(sm) {
      transform: translateY(100%);
    }
  }

  @include media-breakpoint-up(md) {
    width: 350px;
    top: 50%;
    padding: 18px $spacer * 4 $spacer * 4;
    transform: translateY(-50%);
    box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.24);
    right: $spacer * 5;
  }

  @include media-breakpoint-only(xl) {
    margin-right: - (map-get($container-max-widths, xl) / 2 - ($grid-gutter-width-base / 2));
    right: 50%;
  }

  @include media-breakpoint-down(sm) {
    left: 0;
    bottom: 0;
    right: 0;
    text-align: center;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
  }

  &__title {
    @extend %heading-font;

    @include media-breakpoint-up(md) {
      font-size: 38px;
      margin-bottom: $spacer;
      line-height: 1.11;
    }

    @include media-breakpoint-down(sm) {
      font-weight: 300;

      @include text-font;
    }
  }

  &__text {
    @include media-breakpoint-up(md) {
      font-size: 24px;
      margin-bottom: 28px;
      line-height: 1.33;
      font-weight: 300;
    }

    @include media-breakpoint-down(sm) {
      margin-left: $spacer * 2;
      font-weight: 300;
    }

    @include media-breakpoint-down(xs) {
      margin-left: $spacer;
      margin-right: auto;
    }
  }

  &__content {
    @include media-breakpoint-down(sm) {
      display: flex;
      align-items: center;
      padding: $spacer;
      justify-content: center;
      font-size: 16px;
    }

    @include media-breakpoint-down(xs) {
      font-size: 13px;
      padding: $spacer ($spacer * 4);
    }
  }

  &__close {
    position: absolute;
    cursor: pointer;
    transition: $transition-base;

    @include media-breakpoint-up(md) {
      right: $spacer * 4;
      top: $spacer * 4;
    }

    @include media-breakpoint-down(sm) {
      right: $spacer * 4;
      top: 50%;
      transform: translateY(-50%);
    }

    @include media-breakpoint-down(xs) {
      right: 6px;
    }

    @include hover-focus-active {
      &::after {
        opacity: 0.6;
        transform: rotate(180deg);
      }
    }

    &::after {
      background: transparent url("../images/icon__20x20_close--dark.svg") no-repeat center center;
      content: "";
      display: block;
      width: 20px;
      height: 20px;
      transition: $transition-base;
    }
  }

  &__button {
    @include media-breakpoint-up(md) {
      background-color: $dark;
      border-radius: $border-radius;
      border: 0;
      color: $white;
      display: inline-flex;
      font-size: $font-size-xs;
      padding: 0 ($spacer * 3);
      height: 48px;
      align-items: center;
      letter-spacing: $letter-spacing;
      line-height: 1;
      text-transform: uppercase;
      font-weight: 600;
      transition: $transition-base;

      &::after {
        background: transparent url("../images/teaser-arrow-right.svg") no-repeat center center;
        content: "";
        margin-left: $spacer * 2;
        display: block;
        width: 8px;
        height: 13px;
        transition: $transition-base;
      }

      @include hover-focus-active {
        background-color: $black;
        color: $white;
        text-decoration: none;

        &::after {
          transform: translateX($spacer / 2);
        }
      }
    }

    @include media-breakpoint-down(sm) {
      text-decoration: underline;
      margin-left: $spacer * 6;
      color: $body-color;
      font-weight: 300;

      @include hover-focus-active {
        color: $link-color;
        text-decoration: none;
      }
    }
  }
}
