%common {
    background-repeat: no-repeat;
    background-image: url('../images/svg-spritesheet.svg');
}


%icon__16x16_arrow-down {
    background-position: -32px -168px;
    @extend %common;
    width: 16px;
    height: 16px;
}

%icon__16x16_arrow-down-dims {
    width: 16px;
    height: 16px;
}

%icon__16x16_calendar {
    background-position: -168px -119px;
    @extend %common;
    width: 16px;
    height: 16px;
}

%icon__16x16_calendar-dims {
    width: 16px;
    height: 16px;
}

%icon__16x16_check {
    background-position: -16px -168px;
    @extend %common;
    width: 16px;
    height: 16px;
}

%icon__16x16_check-dims {
    width: 16px;
    height: 16px;
}

%icon__16x16_check-hover {
    background-position: 0 -168px;
    @extend %common;
    width: 16px;
    height: 16px;
}

%icon__16x16_check-hover-dims {
    width: 16px;
    height: 16px;
}

%icon__16x16_check-inactive {
    background-position: -168px -151px;
    @extend %common;
    width: 16px;
    height: 16px;
}

%icon__16x16_check-inactive-dims {
    width: 16px;
    height: 16px;
}

%icon__16x16_check-white {
    background-position: -168px -135px;
    @extend %common;
    width: 16px;
    height: 16px;
}

%icon__16x16_check-white-dims {
    width: 16px;
    height: 16px;
}

%icon__24x24_arrow {
    background-position: 0 -144px;
    @extend %common;
    width: 24px;
    height: 24px;
}

%icon__24x24_arrow-dims {
    width: 24px;
    height: 24px;
}

%icon__24x24_arrow-blue {
    background-position: -168px -72px;
    @extend %common;
    width: 24px;
    height: 24px;
}

%icon__24x24_arrow-blue-dims {
    width: 24px;
    height: 24px;
}

%icon__24x24_arrow-grey {
    background-position: -96px -96px;
    @extend %common;
    width: 24px;
    height: 24px;
}

%icon__24x24_arrow-grey-dims {
    width: 24px;
    height: 24px;
}

%icon__24x24_arrow-left {
    background-position: -120px -96px;
    @extend %common;
    width: 24px;
    height: 24px;
}

%icon__24x24_arrow-left-dims {
    width: 24px;
    height: 24px;
}

%icon__24x24_close {
    background-position: -96px -120px;
    @extend %common;
    width: 24px;
    height: 24px;
}

%icon__24x24_close-dims {
    width: 24px;
    height: 24px;
}

%icon__24x24_close-hover {
    background-position: -120px -120px;
    @extend %common;
    width: 24px;
    height: 24px;
}

%icon__24x24_close-hover-dims {
    width: 24px;
    height: 24px;
}

%icon__24x24_close-white {
    background-position: -144px 0;
    @extend %common;
    width: 24px;
    height: 24px;
}

%icon__24x24_close-white-dims {
    width: 24px;
    height: 24px;
}

%icon__24x24_download-white {
    background-position: -144px -24px;
    @extend %common;
    width: 24px;
    height: 24px;
}

%icon__24x24_download-white-dims {
    width: 24px;
    height: 24px;
}

%icon__24x24_facebook {
    background-position: -144px -48px;
    @extend %common;
    width: 24px;
    height: 24px;
}

%icon__24x24_facebook-dims {
    width: 24px;
    height: 24px;
}

%icon__24x24_facebook-grey {
    background-position: -168px -96px;
    @extend %common;
    width: 23px;
    height: 23px;
}

%icon__24x24_facebook-grey-dims {
    width: 23px;
    height: 23px;
}

%icon__24x24_facebook-share {
    background-position: -144px -96px;
    @extend %common;
    width: 24px;
    height: 24px;
}

%icon__24x24_facebook-share-dims {
    width: 24px;
    height: 24px;
}

%icon__24x24_gallery {
    background-position: -144px -120px;
    @extend %common;
    width: 24px;
    height: 24px;
}

%icon__24x24_gallery-dims {
    width: 24px;
    height: 24px;
}

%icon__24x24_gallery-white {
    background-position: -144px -72px;
    @extend %common;
    width: 24px;
    height: 24px;
}

%icon__24x24_gallery-white-dims {
    width: 24px;
    height: 24px;
}

%icon__24x24_linkedin {
    background-position: -24px -144px;
    @extend %common;
    width: 24px;
    height: 24px;
}

%icon__24x24_linkedin-dims {
    width: 24px;
    height: 24px;
}

%icon__24x24_listing {
    background-position: -48px -144px;
    @extend %common;
    width: 24px;
    height: 24px;
}

%icon__24x24_listing-dims {
    width: 24px;
    height: 24px;
}

%icon__24x24_listing-white {
    background-position: -72px -144px;
    @extend %common;
    width: 24px;
    height: 24px;
}

%icon__24x24_listing-white-dims {
    width: 24px;
    height: 24px;
}

%icon__24x24_marina {
    background-position: -96px -144px;
    @extend %common;
    width: 24px;
    height: 24px;
}

%icon__24x24_marina-dims {
    width: 24px;
    height: 24px;
}

%icon__24x24_marina-blue {
    background-position: -120px -144px;
    @extend %common;
    width: 24px;
    height: 24px;
}

%icon__24x24_marina-blue-dims {
    width: 24px;
    height: 24px;
}

%icon__24x24_next {
    background-position: -144px -144px;
    @extend %common;
    width: 24px;
    height: 24px;
}

%icon__24x24_next-dims {
    width: 24px;
    height: 24px;
}

%icon__24x24_parking {
    background-position: -168px 0;
    @extend %common;
    width: 24px;
    height: 24px;
}

%icon__24x24_parking-dims {
    width: 24px;
    height: 24px;
}

%icon__24x24_virtual-tour-white {
    background-position: -168px -24px;
    @extend %common;
    width: 24px;
    height: 24px;
}

%icon__24x24_virtual-tour-white-dims {
    width: 24px;
    height: 24px;
}

%icon__24x24_vk {
    background-position: -168px -48px;
    @extend %common;
    width: 24px;
    height: 24px;
}

%icon__24x24_vk-dims {
    width: 24px;
    height: 24px;
}

%icon__48x48_education {
    background-position: -48px -96px;
    @extend %common;
    width: 48px;
    height: 48px;
}

%icon__48x48_education-dims {
    width: 48px;
    height: 48px;
}

%icon__48x48_entertainment {
    background-position: 0 -96px;
    @extend %common;
    width: 48px;
    height: 48px;
}

%icon__48x48_entertainment-dims {
    width: 48px;
    height: 48px;
}

%icon__48x48_food {
    background-position: -96px -48px;
    @extend %common;
    width: 48px;
    height: 48px;
}

%icon__48x48_food-dims {
    width: 48px;
    height: 48px;
}

%icon__48x48_map-all-pins {
    background-position: -96px 0;
    @extend %common;
    width: 48px;
    height: 48px;
}

%icon__48x48_map-all-pins-dims {
    width: 48px;
    height: 48px;
}

%icon__48x48_map-transport {
    background-position: -48px -48px;
    @extend %common;
    width: 48px;
    height: 48px;
}

%icon__48x48_map-transport-dims {
    width: 48px;
    height: 48px;
}

%icon__48x48_shopping {
    background-position: 0 -48px;
    @extend %common;
    width: 48px;
    height: 48px;
}

%icon__48x48_shopping-dims {
    width: 48px;
    height: 48px;
}

%icon__48x48_sports {
    background-position: -48px 0;
    @extend %common;
    width: 48px;
    height: 48px;
}

%icon__48x48_sports-dims {
    width: 48px;
    height: 48px;
}

%icon__48x48_transport {
    background-position: 0 0;
    @extend %common;
    width: 48px;
    height: 48px;
}

%icon__48x48_transport-dims {
    width: 48px;
    height: 48px;
}
