.section__area-plans {
  position: relative;
}

.area-plans {
  &__content {
    pointer-events: none;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    z-index: 2;

    @include media-breakpoint-down(sm) {
      position: relative;
      left: auto;
      top: auto;
      right: auto;
    }
  }

  &__navigation {
    pointer-events: auto;

    select.form-control {
      background-position: 100% 50%;
    }
  }

  &__maps {

  }

  &__image {
    width: 100%;
  }
}

.area-map {
  &__group {
  }

  &__area {
    background: rgba(0, 85, 166, 0.5);
    position: absolute;
  }
}

.section__house-plan {
  width: 100%;
  max-width: 235px;
  margin-left: auto;
  margin-right: auto;

  @include mb-down(sm) {
    max-width: 100%;
  }
}

.house-plan {
  &__street-name {
    border-bottom: 1px solid #7fa5c9;
    border-top: 1px solid #7fa5c9;
    padding-top: 5px;
    padding-bottom: 5px;
  }

  &__inner-wrap {
    margin-bottom: -25px;
    margin-left: -40px;
    margin-right: -40px;
  }

  &__contacts {
    a {
      color: #fff;
    }
  }
}

.apartment-plan {
  &__download {
    right: 0;
    bottom: 0;

    @include media-breakpoint-up(md) {
      bottom: -40px;
    }
  }

  &__compass {
    background: transparent url("../images/compass.png") no-repeat center center;
    background-size: 72px 76px;
    position: absolute;
    right: 40px;
    top: 40px;
    width: 72px;
    height: 76px;
    z-index: 2;

    &--top-left {
      transform: rotate(0);
    }

    &--top-center {
      transform: rotate(45deg);
    }

    &--top-right {
      transform: rotate(90deg);
    }

    &--right {
      transform: rotate(135deg);
    }

    &--bottom-right {
      transform: rotate(180deg);
    }

    &--bottom-center {
      transform: rotate(225deg);
    }

    &--bottom-left {
      transform: rotate(270deg);
    }

    &--left {
      transform: rotate(315deg);
    }

    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
      background: transparent url("../images/compass@2x.png") no-repeat center center;
      background-size: 72px 76px;
    }
  }

  &__column {
    @include mb-down(sm) {
      padding-left: 0;
      padding-right: 0;
    }
  }

  &__wrap {
    @include mb-down(sm) {
      background-color: #fff;
    }
  }

  &__back-button {

    @include mb-up(md) {
      position: absolute;
      left: 20px;
      top: 50px;
    }
  }

  &__image {
    display: none;

    &--active {
      display: block;
    }
  }
}

#house__parkside,
#house__street,
#house__seaside {
  cursor: pointer;
  opacity: 0.5;
  transition: 0.2s opacity ease;

  &.active,
  &:hover {
    opacity: 1;
  }
}

#house__parkside {
  .active--parkside & {
    opacity: 1;
  }
}

#house__street {
  .active--street & {
    opacity: 1;
  }
}

#house__seaside {
  .active--seaside & {
    opacity: 1;
  }
}
