.modal {
  &--fullpage {
    .modal-dialog {
      max-width: 100%;
      margin: 0;
    }

    .modal-dialog,
    .modal-content,
    .modal-body {
      border: 0;
      height: 100%;
    }

    .modal-body {
      padding: 0;
    }
  }

  .modal-header {
    @include media-breakpoint-down(sm) {
      padding: 30px 30px 0px 30px;
    }

    .modal-title {
      color: $brand-default;
      font-weight: 300;
      text-transform: uppercase;
    }
  }

  .modal-body {
    @include media-breakpoint-down(sm) {
      padding: 10px 30px 30px 30px;
    }

    .textarea-wrap {
      .form-control-label {
        display: none;
      }
    }

    textarea.form-control {
      border: 1px solid #b2b4b6;
      padding-left: 20px;
      padding-top: 20px;
      height: 290px;

      &::-webkit-input-placeholder {
        color: $text-color;

        @include media-breakpoint-down(sm) {
          font-size: 12px;
        }
      }
    }

    .help-block {
      @include media-breakpoint-down(sm) {
        text-align: center;
      }
    }

    .alert {
      margin-top: 20px;
      margin-bottom: 0;
      border-left: 0;
      border-top: 0;
      border-right: 0;
      text-align: center;
    }
  }
}

.map-popover {
  background-color: #fff;
  color: $text-color;
  font-size: 16px;
  padding: 30px;
  width: 315px;
  box-shadow: 0 12px 15px 0 rgba(0, 0, 0, 0.5);
  border-radius: 0;
  opacity: 0;
  position: absolute;
  pointer-events: none;
  text-align: left;
  z-index: 10;
  transition: 0.2s all linear;
  transform: translateY(-10px) !important;

  &--visible {
    opacity: 1;
    transform: translateY(0) !important;
  }

  p {
    color: $text-color;

    &:last-child {
      margin-bottom: 0;
    }
  }

  @include mb-down(lg) {
    width: auto;
    padding: 20px 30px;
  }

  @include mb-down(md) {
    padding: 15px 25px;

    h3 {
      font-size: $font-size-h4;
    }

    p {
      font-size: 14px;
    }
  }

  &:after {
    content: "";
    position: absolute;

    box-shadow: none;
    border-style: solid;
    border-width: 20px 0 20px 20px;
    border-color: transparent transparent transparent #ffffff;
    left: 0;
    top: calc(100% - 20px);
    transform: none;
    width: 0;
    height: 0;
  }

  &.building-popover--1 {
    bottom: 50%;
    left: 30%;
  }

  &.building-popover--2 {
    right: 43%;
    bottom: 62%;
  }

  &.building-popover--3 {
    bottom: 51%;
    right: 37%;

    @include mb-up(lg) {
      right: 39%;
    }
  }

  &.building-popover--2,
  &.building-popover--3 {
    &:after {
      left: auto;
      right: 0;
      border-width: 20px 20px 20px 0;
      border-color: transparent #fff transparent transparent;
    }
  }
}

.close {
  opacity: 1;

  span {
    @extend %icon__24x24_close !optional;
    display: block;
  }

  &:hover {
    span {
      @extend %icon__24x24_close-hover !optional;
    }
  }
}
