.p-relative {
  position: relative;
}

.map-infowindow {
  padding: 15px;
  font-size: 16px;
  line-height: 1.75;

  p {
    font-family: $font-family-base;
    font-weight: 400;

    &:last-child {
      margin-bottom: 0;
    }

    strong {
      font-weight: bold;
    }
  }
}

.sea-waves {
  background: transparent url("../images/sea-wave.svg") repeat-x center top;
  height: 25px;
  margin-top: 5px;
}

.embed-responsive-21by9 {
  @include mb-down(sm) {
    &::before {
      padding-top: percentage(3 / 4);
    }
  }
}
