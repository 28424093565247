.home {
  position: relative;
}

.page-content {
  &__wrap {
    position: relative;
  }
}

.page-header {
  &--with-background {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center top;
    position: relative;
    height: 550px;
    display: flex;
    align-items: center;
    justify-content: center;

    @include mb-down(sm) {
      height: auto;
    }
  }

  &__background {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    height: 550px;
  }

  &--default {
    .page-header__content {
      p {
        @extend .text-white;
      }
    }
  }

  &--skinny {
    height: 90px;

    @include mb-only(sm) {
      height: 135px;
    }

    @include mb-up(sm) {
      max-height: none;
    }

    @include mb-up(md) {
      height: 190px;
    }
  }

  &--fixed {
    background-color: #fff;
    border-bottom: 1px solid #e5e5e5;
    z-index: 10;

    .admin-bar & {
      //top: 222px;
    }

    ul {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    li {
      display: block;
    }

    li:not(:first-child) {
      position: relative;

      &:before {
        background-color: #b2b4b6;
        border-radius: 50%;
        content: '';
        position: absolute;
        left: -2px;
        top: 50%;
        margin-top: -3px;
        width: 4px;
        height: 4px;
      }
    }

    a {
      letter-spacing: 3.8px;

      &:hover {
        text-decoration: none;
      }
    }
  }

  &--inversed {

  }

  &__content {
    .display-1 {
      @include mb-down(md) {
        font-size: $font-size-h1;
      }
    }

    p {
      @extend .lead;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
