
.tablesorter .tablesorter-header {
	outline: none;
	cursor: pointer;
}
.table.tablesorter thead th{
	vertical-align: middle;
}
.tablesorter .tablesorter-header-inner {
	display: inline-block;
	padding: 0 10px 0 5px;
	position: relative;
	line-height: 1;
}
.tablesorter .tablesorter-header-inner:before {
	content: '';
	position: absolute;
	height: 0; width: 0;
	border-style: solid;
	border-width: 0 3px 4px 3px;
	border-color: transparent transparent #ffffff transparent;
	right: -5px;
	top: 50%;
	margin-top: -4px;
	z-index: 1;
}
.tablesorter .tablesorter-header-inner:after{
	content: '';
	position: absolute;
	height: 0; width: 0;
	border-style: solid;
	right: -5px;
	top: 50%;
	margin-top: 4px;
	z-index: 1;
	border-width: 4px 3px 0 3px;
	border-color: #ffffff transparent transparent transparent;
}
.tablesorter .tablesorter-header.sorter-false {
	cursor: default;
}
.tablesorter .tablesorter-header.sorter-false .tablesorter-header-inner {
	padding: 0;
}
.tablesorter .tablesorter-header.sorter-false .tablesorter-header-inner:before, .tablesorter .tablesorter-header.sorter-false .tablesorter-header-inner:after {
	display: none;
}

.home .fullpage__title {
   padding-top: 1em;
}

.cc-color-override--879894922 {
    padding: 0px !important;
    padding-left: 20px !important;
    background: rgba(0, 85, 166,0.6) !important;
    -webkit-transition: width 2s; /* Safari */
    transition: width 2s;
    max-width: 100% !important;
}
.cc-color-override--879894922 .cc-dismiss {
  border: 1px solid white !important;
  background: transparent !important;
  color: white !important;
  font-family: Proba Pro,sans-serif !important;
}
.cc-color-override--879894922 .cc-dismiss:hover {
  text-decoration: none !important;
  background-color: white !important;
  color: rgba(0, 85, 166,0.6) !important;
}
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 769px) {
  .cc-grower {
      max-height: 130px !important;
      border: 0px !important;
      position: absolute !important;
      z-index: 100 !important;
      bottom: 0px !important;
      width: 100% !important;
      max-width: 100% !important;
  }
  .cc-grower .cc-color-override--879894922 {
      padding-bottom: 7px !important;
      padding-top: 7px !important;
      padding-right: 5px !important;
  }
}

.contacts__cta-inquire.trigger-modal {
   background-color: #C3167C;
   padding: 5px 15px;
   border-bottom: none;
   color: white !important;
}

.grsTemplate2 .grsMasImFrCont {
   border: none !important;
   box-shadow: none !important;
}

.grsTemplate2 .grsMasImFrCont .grsIm {
   -webkit-transition: 0.3s transform ease-in-out !important;
   transition: 0.3s transform ease-in-out, 0.3s opacity ease-in-out !important;
   -webkit-transform: scale3d(1,1,1) translate(0px) !important;
   -ms-transform: scale3d(1,1,1) translate(0px) !important;
   transform: scale3d(1,1,1) translate(0px) !important;
}

.grsTemplate2 .grsMasImFrCont:hover .grsIm {
   -webkit-transform: scale3d(1.2,1.2,1.2) translate(15px) !important;
   -ms-transform: scale3d(1.2,1.2,1.2) translate(15px) !important;
   transform: scale3d(1.2,1.2,1.2) translate(15px) !important;
}

.foogallery-masonry {
   margin-top: -85px !important;
}

.fbx-inner {
    border: none !important;
        border-radius: 0px !important;
}
.fbx-rounded .fbx-item-image{
 border-radius: 0px !important;
}
.fbx-inner .fbx-next, .fbx-inner .fbx-prev {
   background-color: transparent !important;
}
.fbx-inner .fbx-next::before, .fbx-inner .fbx-prev::before {
   color: white !important;
}


/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .area-plans__content .area-plans__navigation ul a:first-of-type {
      display: none;
  }
  .aside-menu__nav .aside-navigation li ul {
      display: none;
  }
   .aside-menu__nav .aside-navigation .mobile-marina {
      display: none;
   }
   .table td, .table th {
       padding: 12px 5px;
   }
   .tablesorter .tablesorter-header-inner {
       padding: 0 8px 0 5px;
   }
}

.dont_break {
   white-space: nowrap;
}

.page-id-1371 .table.tablesorter thead th {
   padding-left: 8px;
}

@media only screen and (max-width: 600px) {
   .page-id-1105 .section__pricelist .tablesorter-headerRow th.column-specialprice span,
   .page-id-1371 .section__pricelist .tablesorter-headerRow th.column-specialprice span {
      margin-left: -20px;
   }

}


@media only screen and (max-width: 1024px) {
   .page-id-1105 .section__pricelist .tablesorter-headerRow th.column-specialprice span,
   .page-id-1105 .section__pricelist .tablesorter-headerRow th.column-specialprice,
   .page-id-1371  .section__pricelist .tablesorter-headerRow th.column-specialprice,
   .page-id-1371  .section__pricelist .tablesorter-headerRow th.column-specialprice span {
     padding-left: 0px;
     padding-right: 10px;
     font-size: 12px;
     font-weight: bold;
   }

   .table--pricelist thead th:first-child {
       padding-left: 8px;
   }
   .table--pricelist tbody td {
       padding-right: 7px;
    }

}
