.owl-loaded {
  position: relative;
}

.owl-nav,
.owl-dots {
  &.disabled {
    display: none;
  }
}

.owl-nav {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  pointer-events: none;

  .owl-next,
  .owl-prev {
    background-color: $brand-default;
    width: 75px;
    height: 150px;
    display: block;

    overflow: hidden;
    text-indent: -999px;
    position: absolute;
    top: 50%;
    margin-top: -75px;
    pointer-events: auto;

    @include mb-down(sm) {
      background-color: transparent;
    }

    &.disabled {
      opacity: 0.3;
    }

    span {
      content: '';
      display: block;
      position: absolute;
      top: 50%;
      margin-top: -12px;
      left: 20px;

      @extend %icon__24x24_next;
    }
  }

  .owl-next {
    border-bottom-left-radius: 150px;
    border-top-left-radius: 150px;
    right: 0;

    span {
      transform: rotate(180deg);
      left: auto;
      right: 20px;
    }
  }

  .owl-prev {
    border-bottom-right-radius: 150px;
    border-top-right-radius: 150px;
    left: 0;
  }
}
