.noUi-target {
  &.noUi-horizontal {
    background: none;
    border-radius: 0;
    border: 0;
    box-shadow: none;
    height: 5px;

    .noUi-base {
      .noUi-origin {
        top: 7px;

        .noUi-handle {
          background: none;
          border: none;
          box-shadow: none;
          width: 24px;
          height: 24px;
          left: -12px;

          &:after {
            display: none;
          }

          &:before {
            background-color: #fff;
            border-radius: 50%;
            width: 13px;
            height: 13px;
            top: 50%;
            left: 50%;
            margin-left: -6px;
            margin-top: -6px;
          }
        }
      }
    }
  }
}
