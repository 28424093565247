.pagination {
  display: flex;

  .page-item {
    margin-left: $grid-gutter-width-base;

    &:first-child {
      margin-left: 0;
    }

    &.active {
      .page-link {
        box-shadow: inset 0 0 0 1px $brand-default;
      }
    }

    &:not(.page-item__next):not(.page-item__previous) {
      .page-link {
        box-shadow: inset 0 0 0 1px #fff;

        &:hover {
          box-shadow: inset 0 0 0 3px $brand-default;
        }
      }
    }

    &__next,
    &__first,
    &__previous,
    &__last {
      .page-link {
        background-color: transparent;
      }
    }

    .page-link {
      border-radius: 50%;
      width: 48px;
      height: 48px;
      text-align: center;
      transition: 0.2s all linear;
    }
  }
}
