.carousel {
  &.owl-carousel {
    // Styling dots
    .owl-nav.disabled + .owl-dots {
      margin-top: 0;
    }

    .owl-dots {
      pointer-events: none;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 35px;

      @include mb-down(sm) {
        bottom: 15px;
      }

      .owl-dot {
        pointer-events: auto;
      }
    }
  }
}
