// WordPress Generated Classes
// http://codex.wordpress.org/CSS#WordPress_Generated_Classes

// Media alignment
.alignnone {
  margin-left: 0;
  margin-right: 0;
  max-width: 100%;
  height: auto;
}
.aligncenter {
  display: block;
  margin: $spacer auto;
  height: auto;
}
.alignleft,
.alignright {
  margin-bottom: $spacer;
  margin-top: $spacer;
  height: auto;

  @include mb-down(sm) {
    display: block;
    width: 100%;
  }
}

@include media-breakpoint-up(sm) {
  // Only float if not on an extra small device
  .alignleft {
    float: left;
    margin-right: ($spacer);
  }
  .alignright {
    float: right;
    margin-left: $spacer;
  }
}

img.alignnone,
img.aligncenter,
img.alignleft,
img.alignright {
  @extend .img-fluid;
}

// Captions
.wp-caption {
  max-width: 100%;
  @extend .figure-img;

  img {
    @extend .img-fluid;
  }
}

.wp-caption-text {
  @extend .figure-caption;
}

// Text meant only for screen readers
.screen-reader-text {
  @extend .sr-only;
  @extend .sr-only-focusable;
}
