h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  &:not(.modal-title) {
    margin-top: 60px;
  }
}

h1 + h2,
.h1 + .h2,
h2 + h3,
.h2 + .h3,
h3 + h4,
.h3 + .h4,
h4 + h5,
.h4 + .h5,
h5 + h6,
.h5 + .h6 {
  margin-top: -25px;
}

h1,
.h1,
h2,
.h2 {
    text-transform: uppercase;
}

h2,
.h2 {
    font-weight: normal;
}

h3,
.h3 {
    font-weight: normal;
}

h4,
.h4 {
    font-weight: normal;
}

.lh-15 {
  line-height: 1.5;
}

.lh-175 {
  line-height: 1.75;
}

.font-weight-600 {
  font-weight: 600;
}

.font-weight-500 {
  font-weight: 500;
}

.font-weight-300 {
  font-weight: 300;
}

@include text-emphasis-variant('.text-warning', #bbb457);
