.table {
  thead:not(.thead-inverse) {
    @extend .thead-default;
  }

  tbody {
    th,
    td {
      color: $text-color;
    }
  }

  thead {
    th {
      border-bottom: 5px solid $brand-default;
      border-top: 0;
      font-weight: normal;
    }
  }

  .thead-inverse {
    th {
      border-bottom-color: #b2b4b6;
      font-weight: bold;
    }
  }

  &.table-bordered {
    tbody {
      th,
      td {
        border-bottom-width: 1px;
      }
    }
  }

  &--pricelist {
    thead {
      th {
        &:first-child {
          padding-right: 5px;

          @include mb-up(sm) {
            padding-left: 30px;
          }
        }
      }
    }

    tbody {
      th {
        font-weight: normal;
        padding-right: 5px;

        @include mb-up(sm) {
          padding-left: 30px;
        }
      }

      td,
      th {
        cursor: pointer;

        a {
          color: $text-color;
        }
      }

      tr {
        &.apartment__row {
          &--sold {
            @extend .table-danger;
          }

          &--booked {
            @extend .table-warning;
          }

          &--sold {
            opacity: 0.3;

            th,
            td {
              cursor: default;
            }
          }

          &:hover {
            @include mb-up(md) {
              td,
              th {
                background-color: $gray-light;
              }
            }
          }

          @include mb-up(md) {
            td,
            th {
              background-color: $table-bg;
            }
          }
        }
      }
    }
  }

  &--apartment-information {
    background-color: transparent;

    tbody {
      th,
      td {
        color: #fff;
        line-height: 1.2;
        padding: 5px 0;
      }
    }
  }

  .column-price {
    width: 13%;
  }

  .column-status {
    width: 11%;
  }

  .column-sauna,
  .column-fireplace {
    width: 9%;
  }

  .column-terrace {
    width: 12%;
  }

  .column-rooms {
    width: 14%;
  }

  .column-price,
  .column-status,
  .column-terrace,
  .column-sauna,
  .column-fireplace,
  .column-rooms {
    @include mb-down(md) {
      width: auto;
    }
  }
}
